/* Common */

img {
  height: 100%;
  transition: all 0.5s;
}

button {
  background-color: unset;
  border: unset;
  border-radius: 5%;
}

button:hover {
  background-color: #ccc;
  cursor: default;
}

#app {
  display: flex;
}

.hidden {
  width: 0px !important;
  height: 0px !important;
  visibility: hidden;
}

.rotate-90 {
  transform: rotate(90deg);
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d3d1cb;
}

::-webkit-scrollbar-track {
  background: #edece9;
}

/* Icon */

.icon {
  content: "";
  background-size: 12px;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  display: inline-block;
  transition: transform 0.5s;
  opacity: 0.65;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
}

.icon.dropdown-documents-button {
  background-image: url(/src/assets/images/arrow.png);
}

.icon.remove-document-button {
  background-image: url(/src/assets/images/close.png);
}

.icon.add-sub-document-button {
  background-image: url(/src/assets/images/plus.png);
}

.icon.add-document-button {
  background-image: url(/src/assets/images/plus.png);
  margin-right: 0.5rem;
}

/* Sidebar */

.sidebar {
  min-width: 260px;
  width: 260px;
  max-width: 25vw;
  padding: 0.5rem 0.5rem 0 0.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-header {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sidebar .sidebar-header h1 {
  font-size: 24px;
  margin: 15px 0;
  cursor: pointer;
}

.sidebar .document-list {
  width: 100%;
  overflow: hidden auto;
  cursor: pointer;
}

.sidebar .document-list ul {
  transition: all 0.5s;
}

.sidebar .document-list .document-item {
  display: flex;
  height: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  font-size: 13px;
}

.sidebar .document-list .document-item:hover {
  background-color: rgb(240, 240, 240);
}

.sidebar .document-list .document-item.selected {
  background-color: rgb(230, 230, 230);
}

.sidebar .document-list .document-item .document-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.sidebar .document-list .document-item .document-title :hover {
  max-width: 80%;
}

.sidebar .document-list .document-item .document-button-group {
  margin-left: auto;
  flex-shrink: 0;
  width: 35px;
  display: flex;
  justify-content: space-around;
}

.sidebar .document-list .no-sub-documents {
  cursor: default;
  user-select: none;
  font-size: 13px;
  color: rgb(100, 100, 100);
}

.sidebar .close {
  display: none;
}

.sidebar .open {
  display: block;
}

/* Editor */

.editor {
  max-width: 720px;
  margin: 0 auto;
  padding: 0.5rem;
}

.editor .document-title {
  border: 0px;
  font-size: 48px;
  padding: 0.5rem;
}

.editor .document-content {
  margin-top: 1rem;
  border: 0px;
  padding: 0.5rem;
  resize: none;
  min-height: 600px;
}

.editor [contenteditable] {
  outline: 0px solid transparent;
  cursor: text;
}

.editor [contenteditable]:empty::before {
  content: attr(placeholder);
  color: rgb(160, 160, 160);
  display: block;
}

/* Resizer */

.resizer {
  width: 2px;
  background-color: rgb(200, 200, 200);
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0.5rem;
}

/* Content */

.content {
  width: 100%;
  padding-left: 0.5rem;
}

/* SubDocumentList */

.sub-documents-link {
  max-width: 720px;
  margin: 0 auto;
  padding: 0.5rem;
  margin-bottom: 50px;
}

.sub-documents-link * {
  padding: 0 0.5rem;
}

.sub-documents-link h1 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.sub-documents-link a {
  text-decoration: none;
  color: rgb(60, 70, 255);
}

/* Modal */

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 40px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  transform: translateX(-50%) translateY(-50%);
}
