img {
  height: 100%;
  transition: all .5s;
}

button {
  background-color: unset;
  border: unset;
  border-radius: 5%;
}

button:hover {
  cursor: default;
  background-color: #ccc;
}

#app {
  display: flex;
}

.hidden {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

::-webkit-scrollbar {
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #d3d1cb;
}

::-webkit-scrollbar-track {
  background: #edece9;
}

.icon {
  content: "";
  width: 12px;
  height: 12px;
  opacity: .65;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 12px;
  transition: transform .5s;
  display: inline-block;
}

.icon:hover {
  opacity: 1;
}

.icon.dropdown-documents-button {
  background-image: url("arrow.ffe3653e.png");
}

.icon.remove-document-button {
  background-image: url("close.aebdcc9a.png");
}

.icon.add-sub-document-button {
  background-image: url("plus.a79afc2c.png");
}

.icon.add-document-button {
  background-image: url("plus.a79afc2c.png");
  margin-right: .5rem;
}

.sidebar {
  min-width: 260px;
  width: 260px;
  max-width: 25vw;
  flex-direction: column;
  padding: .5rem .5rem 0;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
}

.sidebar .sidebar-header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  display: flex;
}

.sidebar .sidebar-header h1 {
  cursor: pointer;
  margin: 15px 0;
  font-size: 24px;
}

.sidebar .document-list {
  width: 100%;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .document-list ul {
  transition: all .5s;
}

.sidebar .document-list .document-item {
  height: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  font-size: 13px;
  display: flex;
}

.sidebar .document-list .document-item:hover {
  background-color: #f0f0f0;
}

.sidebar .document-list .document-item.selected {
  background-color: #e6e6e6;
}

.sidebar .document-list .document-item .document-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.sidebar .document-list .document-item .document-title :hover {
  max-width: 80%;
}

.sidebar .document-list .document-item .document-button-group {
  width: 35px;
  flex-shrink: 0;
  justify-content: space-around;
  margin-left: auto;
  display: flex;
}

.sidebar .document-list .no-sub-documents {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  color: #646464;
  font-size: 13px;
}

.sidebar .close {
  display: none;
}

.sidebar .open {
  display: block;
}

.editor {
  max-width: 720px;
  margin: 0 auto;
  padding: .5rem;
}

.editor .document-title {
  border: 0;
  padding: .5rem;
  font-size: 48px;
}

.editor .document-content {
  resize: none;
  min-height: 600px;
  border: 0;
  margin-top: 1rem;
  padding: .5rem;
}

.editor [contenteditable] {
  cursor: text;
  outline: 0 solid #0000;
}

.editor [contenteditable]:empty:before {
  content: attr(placeholder);
  color: #a0a0a0;
  display: block;
}

.resizer {
  width: 2px;
  height: 100%;
  cursor: col-resize;
  background-color: #c8c8c8;
  position: absolute;
  top: 0;
  right: .5rem;
}

.content {
  width: 100%;
  padding-left: .5rem;
}

.sub-documents-link {
  max-width: 720px;
  margin: 0 auto 50px;
  padding: .5rem;
}

.sub-documents-link * {
  padding: 0 .5rem;
}

.sub-documents-link h1 {
  margin: .5rem 0;
  font-size: 1rem;
}

.sub-documents-link a {
  color: #3c46ff;
  text-decoration: none;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: #0006;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-content {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
  box-shadow: 0 2px 3px #22242626;
}

/*# sourceMappingURL=index.ef93ffbb.css.map */
